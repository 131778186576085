import SimpleBreakpoints from 'simple-breakpoints'

const MOBILE_WIDTH = 480

const breakpoints = new SimpleBreakpoints({
  mobile: MOBILE_WIDTH,
  tablet: Infinity,
  small_desktop: Infinity,
  large_desktop: Infinity,
})

export default breakpoints
