<template>
  <div
    ref="chartContainer"
    v-resize="resizeHandler"
    class="h-100"
  >
    <div class="controls-header">
      <slot />
    </div>

    <trading-vue
      ref="tradingVue"
      :data="dc"
      :overlays="overlays"
      :width="chartWidth"
      :height="chartHeight"
      :toolbar="false"

      :color-back="chartColors.back"
      :color-text="chartColors.text"
      :color-grid="chartColors.grid"
      :color-scale="chartColors.scale"

      @range-changed="rangeChangeHandler"
      @grid-mousedown="onmousedown"
    />
  </div>
</template>

<script>
import { TradingVue } from 'trading-vue-js'
import ohlcvMapper from '@aitt/shared/ohlcv-mapper'
// import * as time from '@aitt/shared/time'
import { MINUTE } from '@aitt/shared/time'
import Mousetrap from 'mousetrap'

import LinesOverlay from '@/components/Overlays/LinesOverlay'
import RectsOverlay from '@/components/Overlays/RectsOverlay'
import TextsOverlay from '@/components/Overlays/TextsOverlay'
import LevelTool from '@/components/Overlays/LevelTool'

import { useChartStore } from '../store/chart'
import { dataCube } from '../store/chartDataCube'

const chartStore = useChartStore()

export default {
  components: {
    TradingVue,
  },

  props: {

  },

  data() {
    return {
      chartWidth: 800,
      chartHeight: 400,

      dc: dataCube,

      overlays: [
        LinesOverlay,
        RectsOverlay,
        TextsOverlay,
        LevelTool,
      ],

      drawingMode: false,

      chartStore,

      disableRangeTrigger: false,
    }
  },

  computed: {
    brokerData() {
      return {
        brokerId: chartStore.brokerId,
        ticker: chartStore.ticker,
        timeframe: chartStore.timeframe,
        starDate: chartStore.startDate,
        endDate: chartStore.endDate,
      }
    },

    realtimeCandleClosePrice() {
      return chartStore.realtimeCandle.close
    },

    chartColors() {
      const style = getComputedStyle(document.body)

      return {
        back: style.getPropertyValue('--c-backgrounds-app'),
        text: style.getPropertyValue('--c-labels-secondary'),
        scale: style.getPropertyValue('--c-separators-opaque'),
        grid: 'rgba(0,0,0,0)',
      }
    },

    newPosition() {
      return chartStore.getNewPosition
    },

    chartRange() {
      return chartStore.chartRange
    },
  },

  watch: {
    async brokerData(newVal, oldVal) {
      const range = this.$refs.tradingVue.getRange()
      let candles = []
      let patterns = []
      let needToClearChart = false

      try {
        /**
         * Load candles
         */
        candles = await chartStore.loadCandles({
          startDate: this.chartStore.startDate,
          endDate: this.chartStore.endDate,
          overlap: 100,
        })

        /**
         * Load patterns
         */
        patterns = await chartStore.loadPatterns({
          startDate: this.chartStore.startDate,
          endDate: this.chartStore.endDate,
          overlap: 100,
        })
      } catch (e) {
        console.error('Error in Chart.vue', e)
      }

      if (oldVal.ticker !== newVal.ticker) {
        needToClearChart = true
      }

      if (oldVal.timeframe !== newVal.timeframe) {
        needToClearChart = true
      }

      if (!this.chartStore.startDate) {
        needToClearChart = true
        this.disableRangeTrigger = true
      }

      if (!range.length) {
        needToClearChart = true
      }

      if (this.chartStore.startDate === undefined) {
        this.chartStore.setBrokerData({
          startDate: candles[0].date,
          endDate: candles[candles.length - 1].date,
        })
      }

      this.drawCandles(candles, needToClearChart)
      this.drawPatterns(patterns, needToClearChart)

      if (!range.length) {
        this.disableRangeTrigger = true
        this.$nextTick(() => {
          this.$refs.tradingVue.setRange(candles[0].date, candles[candles.length - 1].date)
        })
      }
    },

    realtimeCandleClosePrice(newVal) {
      this.drawCandles([ chartStore.realtimeCandle ], false)
    },

    newPosition(newVal) {
      this.positionLines(newVal)
    },

    chartRange(range) {
      this.$refs.tradingVue.setRange(range[0], range[1])
    },
  },

  async mounted() {
    window.addEventListener('resize', this.resizeHandler)

    this.resizeHandler()
    this.bindKeys()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    this.unbindKeys()
  },

  methods: {

    rangeChangeHandler(val) {
      clearTimeout(this.rangeTimer)

      this.rangeTimer = setTimeout(async () => {
        if (this.disableRangeTrigger) {
          this.disableRangeTrigger = false

          return
        }

        const range = this.$refs.tradingVue.getRange()

        this.$emit('range', [parseInt(range[0]), parseInt(range[1])])
      }, 100)
    },

    resizeHandler() {
      const { width, height } = this.$refs.chartContainer.getBoundingClientRect()

      this.chartWidth = width
      this.chartHeight = height
    },

    /**
     * Patterns
     */
    clearPatterns() {
      this.dc.del('DataLines')
      this.dc.del('RectsOverlay')
      this.dc.del('TextsOverlay')
    },

    drawPatterns(patterns, clearChart) {
      this.clearPatterns()
      /**
       * Draw lines
       */
      if (patterns.line) {
        this.dc.add('onchart', {
          type: 'LinesOverlay',
          name: 'DataLines',
          data: [],
          settings: {
            lines: patterns.line,
            'z-index': 2,
          },
        })
      }

      /**
       * Draw rects
       */
      if (patterns.rect) {
        this.dc.add('onchart', {
          type: 'RectsOverlay',
          data: [],
          settings: {
            rects: patterns.rect,
            'z-index': 2,
          },
        })
      }

      /**
       * Draw texts
       */
      if (patterns.text) {
        this.dc.add('onchart', {
          type: 'TextsOverlay',
          data: [],
          settings: {
            texts: patterns.text,
            'z-index': 2,
          },
        })
      }
    },

    getRange() {
      return this.$refs.tradingVue.getRange()
    },

    async drawCandles(candles, clear = true) {
      const flatCandles = candles.map(ohlcvMapper)

      if (clear) {
        this.dc.set('chart', {
          type: 'Candles',
          data: flatCandles,
          settings: {
            priceLine: true,
          },
        })
      } else {
        this.dc.merge('chart.data', flatCandles)
      }
    },

    goto(candleCount) {
      const right = this.$refs.tradingVue.getRange()[1]

      this.$refs.tradingVue.goto(right + MINUTE * this.brokerData.timeframe * candleCount)
    },

    addTechLines(lines) {
      const techLines = this.dc.get('TechLines')

      if (techLines.length === 0) {
        this.dc.add('onchart', {
          type: 'LinesOverlay',
          name: 'TechLines',
          data: [],
          settings: {
            lines,
            'z-index': 3,
          },
        })
      } else {
        this.dc.get('TechLines.settings')[0].lines.push(...lines)
      }
    },

    positionLines({ take, safe, stop, startDate = 0, endDate = 99999999999999 }) {
      const positionLines = this.dc.get('PositionLines')

      const takePriceProfitLine = {
        type: 'line',
        points: [
          [startDate, take],
          [endDate, take],
        ],
        color: 'rgba(10, 132, 255, 1)',
        width: 2,
        dashed: null,
      }

      const stopPriceLossLine = {
        type: 'line',
        points: [
          [startDate, stop],
          [endDate + 100000000, stop],
        ],
        color: 'rgba(255, 0, 0, 0.8)',
        width: 2,
        dashed: null,
      }

      const safePriceLine = {
        type: 'line',
        points: [
          [startDate, safe],
          [endDate + 100000000, safe],
        ],
        color: 'rgba(255, 255, 0, 0.8)',
        width: 2,
        dashed: null,
      }

      const lines = [
        takePriceProfitLine,
        stopPriceLossLine,
        safePriceLine,
      ]

      if (positionLines.length === 0) {
        this.dc.add('onchart', {
          type: 'LinesOverlay',
          name: 'PositionLines',
          data: [],
          settings: {
            lines,
            'z-index': 3,
          },
        })
      } else {
        this.dc.get('PositionLines.settings')[0].lines = [ ...lines ]
      }
    },

    bindKeys() {
      Mousetrap.bind('right', () => {
        this.goto(1)
      })

      Mousetrap.bind('left', () => {
        this.goto(-1)
      })

      Mousetrap.bind('shift+right', () => {
        this.goto(10)
      })

      Mousetrap.bind('shift+left', () => {
        this.goto(-10)
      })

      Mousetrap.bind('alt+h', () => {
        const cursor = this.$refs.tradingVue.getCursor()

        this.addTechLines([
          {
            type: 'line',
            points: [
              [0, cursor.y$],
              [99999999999999, cursor.y$],
            ],
            color: 'rgba(0, 0, 255, 0.7)',
            width: 2,
            dashed: null,
          },
        ])
      })

      Mousetrap.bind('alt+v', () => {
        const cursor = this.$refs.tradingVue.getCursor()

        this.addTechLines([
          {
            type: 'line',
            points: [
              [cursor.t, 0],
              [cursor.t, 2],
            ],
            color: 'rgba(0, 0, 255, 0.7)',
            width: 2,
            dashed: null,
          },
        ])
      })

      Mousetrap.bind('esc', () => {
        this.dc.del('TechLines')
      })
    },

    unbindKeys() {
      Mousetrap.unbind('right, left, shift+right, shift+left, esc')
    },

    onmousedown(event) {
      // console.log('===> onmousedown', this.$refs.tradingVue.getCursor())
    },
  },
}
</script>

<style lang="stylus">
  .trading-vue
    position absolute

  .trading-vue-legend
    display none

    .t-vue-ind,
    .t-vue-title
      display none

  .controls-header
    position fixed
    top 5px
    right 72px
    z-index 1
    color #eee

    label
      font-size 12px
      color #aaa
      padding-top 12px

</style>
