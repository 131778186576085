<template>
  <div class="terminal-chart h-100">
    <div class="chart">
      <chart
        ref="chart"
        @range="rangeChangeHandler"
      />
    </div>

    <SheetyContent>
      <slot />
    </SheetyContent>
  </div>
</template>

<script>

import Chart from '@/terminal/components/Chart'
import SheetyContent from '@/terminal/views/SheetyContent'

import { useChartStore } from '@/terminal/store/chart'
import { usePositionsStore } from '@/terminal/store/positions'
import { useLevelsStore } from '@/terminal/store/levels'
// import { useTickersStore } from '@/terminal/store/tickers'
// import { MS_PER_SECOND, MINUTE } from '@aitt/shared/time'

const chartStore = useChartStore()
const positionsStore = usePositionsStore()
const levelsStore = useLevelsStore()
// const tickersStore = useTickersStore()

export default {
  components: {
    Chart,
    SheetyContent,
  },

  data() {
    return {
      tabIndex: 3,
      sheetOptions: {
        width: 320,
      },
      chartStore,
    }
  },

  computed: {
    brokerData() {
      return chartStore.brokerData
    },
  },

  watch: {
    /**
     * Watch broker data object and replace url
     */
    brokerData() {
      this.replaceUrl()

      chartStore.drawDayDelimiters()
    },

  },

  mounted() {
    const paramsFromUrl = this.$route.query
    const startDate = parseInt(paramsFromUrl.startDate)
    const endDate = parseInt(paramsFromUrl.endDate)

    if (startDate && endDate) {
      // console.log('~~~~~~ start with dates', parseInt(paramsFromUrl.startDate), parseInt(paramsFromUrl.endDate))
      chartStore.setBrokerData({
        ...paramsFromUrl,
        startDate: parseInt(paramsFromUrl.startDate),
        endDate: parseInt(paramsFromUrl.endDate),
      })
    } else {
      // console.log('~~~~~~ start without dates')
      chartStore.setBrokerData({
        startDate: undefined,
        endDate: Date.now(),
      })
    }

    chartStore.listenRealTimeCandle(true)
    positionsStore.listenPositionsUpdate(true)
    levelsStore.fetch()
    positionsStore.fetch()
  },

  beforeDestroy() {
    chartStore.listenRealTimeCandle(false)
    positionsStore.listenPositionsUpdate(false)
  },

  methods: {
    /**
     * On chart range changed
     * @param {array} range — array of startDate, endDate
     */
    async rangeChangeHandler(range) {
      chartStore.setBrokerData({
        startDate: parseInt(range[0]),
        endDate: parseInt(range[1]),
      })
    },

    /**
     * Replace query string in url
     */
    replaceUrl() {
      if (!this.isLocationQueryTheSame(chartStore.brokerData)) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...chartStore.brokerData,
            /** to do move out */
            patternId: this.$route.query.patternId,
          },
        }).catch(() => {})
      }
    },

    /**
     * Is query in url the same with another
     * @param {URLSearchParams} query — query string
     */
    isLocationQueryTheSame(query) {
      return new URLSearchParams(this.$route.query).toString() === new URLSearchParams(query).toString()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.terminal-chart
  display flex
  flex-direction column

.chart
  position relative
  height 100%
  width 100%
  flex 1

.sheety
  position relative
  width 100%
  left 0
  bottom 0

  @media $desktop
    position fixed
    width 375px
    left 100px
    bottom var(--chart-timeline-height)

</style>
