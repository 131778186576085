var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'ui-button',
    'ui-button--' + _vm.type,
    'ui-button--' + _vm.size,
    {
      'ui-button--only-icon': _vm.onlyIcon,
      'ui-button--disabled': _vm.disabled,
      'ui-button--loading': _vm.loading,
    } ],on:{"click":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)},"mousedown":function($event){$event.preventDefault();return _vm.onPress.apply(null, arguments)},"touchstart":function($event){$event.preventDefault();return _vm.onPress.apply(null, arguments)}}},[(_vm.icon)?_c('b-icon',{attrs:{"icon":_vm.icon,"font-scale":_vm.iconScale}}):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }