import { defineStore } from 'pinia'
import api from '../api'

const EMPTY_USER = {
  userId: null,
  firstName: null,
  lastName: null,
  telegramUserName: null,
  avatar: null,
}

export const useUserStore = defineStore('user', {
  state: () => ({
    ...EMPTY_USER,

    binance: {
      api: '123',
      key: 'asd',
    },
  }),

  getters: {
    isLoggedIn: state => !!state.userId,
    fullName: state => {
      let fullName = state.firstName

      if (state.lastName) {
        fullName += ' ' + state.lastName
      }

      return fullName
    },
  },

  actions: {
    async telegramLogin(user) {
      const loginResult = await api.authLogin({
        ...user,
      })

      this.mutateUser(loginResult.user)

      console.log('---> logged in', loginResult)
    },

    async loginCheck() {
      const user = await api.authCheck()

      this.mutateUser(user)

      console.log('---> login check', user)
    },

    async logout() {
      await api.authLogout()

      this.mutateUser(EMPTY_USER)
    },

    mutateUser(user) {
      this.$patch({
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        telegramUserName: user.username,
        avatar: user.photo_url,
      })
    },

  },
})
