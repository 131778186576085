<template>
  <div class="tab-bar">
    <router-link
      v-for="(item, i) in items"
      :key="i"
      :to="{name: item.route}"
      class="item"
    >
      <b-icon
        :icon="item.icon"
        font-scale="2.4"
      />
      <p>{{ item.name }}</p>
    </router-link>
  </div>
</template>

<script>

export default {
  data() {
    return {
      items: [
        {
          icon: 'house-door',
          name: 'Home',
          route: 'Terminal — Home',
        },
        {
          icon: 'bar-chart-line',
          name: 'Chart',
          route: 'Terminal — Chart',
        },
        {
          icon: 'pie-chart',
          name: 'Portfolio',
          route: 'Terminal — Portfolio',
        },
        {
          icon: 'newspaper',
          name: 'News',
          route: 'Terminal — News',
        },
        {
          icon: 'person-circle',
          name: 'Profile',
          route: 'Terminal — Profile',
        },
      ],
    }
  },
}
</script>

<style lang="stylus" scoped>
  @import '../styles/global.styl'

  .tab-bar
    position fixed
    z-index 200
    display flex
    bottom 0
    width 100%
    height var(--tab-bar-height)
    background var(--c-backgrounds-app)
    justify-content space-around

    .item
      text-align center
      font-size 10px
      color var(--c-labels-secondary)
      height 60px
      width 60px
      padding-top 6px
      text-decoration none

      &.router-link-active
        color var(--c-accent)

    @media $desktop
      top 0
      left 0
      bottom auto
      width var(--tab-bar-width)
      height 100%
      flex-direction column
      border-top none
      border-right 1px solid var(--c-separators-opaque)

      p
        margin-top 4px

</style>
