<script>
import eventBus from '@/terminal/classes/eventBus'

import Overlay from 'trading-vue-js/src/mixins/overlay'
import Tool from 'trading-vue-js/src/mixins/tool.js'
import Pin from 'trading-vue-js/src/components/primitives/pin.js'
import Seg from 'trading-vue-js/src/components/primitives/seg.js'

import _ from 'lodash'

export default {
  name: 'LevelTool',
  mixins: [Overlay, Tool],

  data() {
    return {}
  },

  computed: {
    sett() {
      return this.$props.settings
    },

    p1() {
      return this.sett.p1
    },

    p2() {
      return this.sett.p2
    },

    back_color() {
      return this.sett.direction > 0 ? 'rgba(0,0,255,0.2)' : 'rgba(255,0,0,0.2)'
    },

    stroke_color() {
      return this.sett.direction > 0 ? 'rgba(0,0,215,0.7)' : 'rgba(215,0,0,0.4)'
    },
  },

  watch: {
    p1() {
      this.emitUpdate()
    },

    p2() {
      this.emitUpdate()
    },

    selected() {
      eventBus.emit('level-selected', {
        id: this.sett.id,
        state: this.selected,
      })
    },
  },

  destroyed() {
    this.$off('custom-event', this.customEventHandler)
  },

  methods: {
    meta_info() {
      return {
        author: 'AiTT',
        version: '1.0.0s',
      }
    },

    init() {
      this.pins.push(new Pin(this, 'p1'))
      this.pins.push(new Pin(this, 'p2'))

      this.$on('custom-event', this.customEventHandler)
    },

    customEventHandler({ event, args }) {
      switch (event) {
        case 'remove-tool':
          eventBus.emit('level-removed', {
            id: this.sett.id,
          })
          break
      }
      // console.log('-----> event', this.sett.id, event, args)
    },

    emitUpdate: _.debounce(function (string) {
      eventBus.emit('level-update', {
        id: this.sett.id,
        startDate: this.p1[0],
        endDate: this.p2[0],
        minPrice: this.p1[1],
        maxPrice: this.p2[1],
      })
    }, 500),

    draw(ctx) {
      if (!this.p1 || !this.p2) {
        return
      }

      const layout = this.$props.layout

      ctx.lineWidth = 1
      ctx.fillStyle = this.back_color

      const x1 = layout.t2screen(this.p1[0])
      const y1 = layout.$2screen(this.p1[1])
      const x2 = layout.t2screen(this.p2[0])
      const y2 = layout.$2screen(this.p2[1])

      ctx.fillRect(x1, y1, x2 - x1, y2 - y1)

      ctx.beginPath()
      ctx.strokeStyle = this.stroke_color

      new Seg(this, ctx).draw([this.p1[0], this.p2[1]], [this.p2[0], this.p2[1]])
      new Seg(this, ctx).draw([this.p1[0], this.p1[1]], [this.p2[0], this.p1[1]])
      new Seg(this, ctx).draw([this.p1[0], this.p1[1]], [this.p1[0], this.p2[1]])
      new Seg(this, ctx).draw([this.p2[0], this.p1[1]], [this.p2[0], this.p2[1]])

      ctx.stroke()

      this.render_pins(ctx)
    },

    use_for() {
      return [ 'LevelTool' ]
    },
  },
}
</script>