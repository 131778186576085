<template>
  <button
    :class="[
      'ui-button',
      'ui-button--' + type,
      'ui-button--' + size,
      {
        'ui-button--only-icon': onlyIcon,
        'ui-button--disabled': disabled,
        'ui-button--loading': loading,
      },
    ]"
    @click.prevent="onClick"
    @mousedown.prevent="onPress"
    @touchstart.prevent="onPress"
  >
    <b-icon
      v-if="icon"
      :icon="icon"
      :font-scale="iconScale"
    />
    <span v-if="$slots.default">
      <slot />
    </span>
  </button>
</template>

<script>

export default {
  components: {
  },
  props: {
    /**
     * Button type
     */
    type: {
      type: Number,
      default: 1,
    },

    /**
     * Icon name
     */
    icon: {
      type: String,
      default: '',
    },

    /**
     * Icon width and height size
     */
    iconScale: {
      type: Number,
      default: 2.4,
    },

    /**
     * Is button disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Is button has loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * True, if button's content is just an icon
     */
    onlyIcon: {
      type: Boolean,
      default: false,
    },

    /**
     * Button size: default, small or large
     */
    size: {
      type: String,
      default: 'default',
    },

    /**
     * Describes the click behavior
     */
    mode: {
      type: String,
      default: 'press',
    },
  },
  computed: {
  },
  methods: {
    /**
     * Emit default click event
     * @returns {void}
     */
    onClick() {
      if (this.disabled) {
        return
      }

      if (this.mode !== 'click') {
        return
      }

      this.$emit('click')
    },

    onPress() {
      if (this.disabled) {
        return
      }

      if (this.mode !== 'press') {
        return
      }

      this.$emit('click')
    },
  },
}
</script>

<style lang="stylus">
.ui-button
  display flex
  justify-content center
  text-decoration none
  white-space nowrap
  border 0
  cursor pointer

  /** Sizes */
  &--default
    height 44px
    padding 0 19px
    border-radius 10px
    font-size var(--body-17)
    line-height 44px

  &--small
    height 32px
    padding 0 15px
    border-radius 8px
    font-size 16px
    line-height 32px

  &--disabled
    opacity 0.5
    pointer-events none

  /** Types */
  &--1
    color var(--c-u-labels-primary)
    background-color var(--c-accent)

    &:hover
      filter saturate(1.5)

    &:active
      filter saturate(0.9)

  &--2
    color var(--c-accent)
    background-color var(--c-fills-quarternary)

    &:hover
      background-color var(--c-fills-tertiary)

    &:active
      filter brightness(0.8)

  &--3
    color var(--c-accent)
    background-color var(--c-u-labels-primary)

    &:hover
      filter brightness(0.99)

    &:active
      filter brightness(0.95)

  &--4
    color var(--c-tints-system-red)
    background-color var(--c-fills-quarternary)

    &:hover
      background-color var(--c-fills-tertiary)

    &:active
      filter brightness(0.8)

</style>
