<template>
  <div class="ui-input">
    <input
      :id="uid"
      :type="type"
      :value="value"
      :step="step"
      :min="min"
      :max="max"
      @input="changeHandler"
    >

    <label
      v-if="!!this.$slots.default"
      :for="uid"
    >
      <slot />
      <template v-if="showValue">: {{ value }}{{ valueUnit }}</template>
    </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: {
      get() {
        console.log('get')
      },

      set(val) {
        console.log('set', val)
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    step: {
      type: Number,
      default: 1,
    },
    width: {
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
    valueUnit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uid: 'input-' + (Math.random() + 1).toString(36).substring(7),
    }
  },

  computed: {

  },

  methods: {
    changeHandler(event) {
      let value = event.target.value

      if (value === '' && this.type === 'number') {
        value = undefined
      }

      this.$emit('input', value)
    },
  },
}
</script>

<style scoped lang="stylus">

.ui-input
  display flex
  background-color var(--c-fills-quarternary)
  border-radius 10px
  align-items center

  input[type="text"], input[type="number"]
    outline none
    border none
    background-image none
    -webkit-box-shadow none
    -moz-box-shadow none
    box-shadow none
    font-size var(--body-17)
    font-family 'San Francisco', BlinkMacSystemFont, sans-serif
    line-height 44px
    height 44px
    width 100%
    color var(--c-labels-primary)
    background transparent
    padding 0 16px

  label
    display none

</style>
