import { defineStore } from 'pinia'
import api from '../api'
import { dataCube } from './chartDataCube'
import eventBus from '@/terminal/classes/eventBus'
import { uid } from 'uid'

/**
 * Listen for level events
 */
eventBus.on('level-update', (data) => {
  console.log('------> level-update', data)
  api.levels.update({
    rectId: data.id,
    ...data,
  })
})

eventBus.on('level-selected', ({ id, state }) => {
  console.log('------> level-selected', id, state)
  if (levelsStore.selectedId === id && state === false) {
    levelsStore.setSelectedId(null)
  } else if (state) {
    levelsStore.setSelectedId(id)
  }
})

eventBus.on('level-removed', (data) => {
  console.log('------> level-removed', data)
  api.levels.remove({
    rectId: data.id,
  })
})

export const useLevelsStore = defineStore('levels', {
  state: () => ({
    levels: [],
    selectedId: null,
  }),

  getters: {
    selectedLevel: state => {
      if (state.selectedId) {
        return state.levels.find(l => l.id === state.selectedId)
      }

      return null
    },
  },

  actions: {
    /**
     * Load levels
     */
    async fetch() {
      const levels = await api.levels.get()

      this.$patch({
        levels,
      })

      this.drawLevels()
    },

    /**
     * Draw all levels from the state
     */
    drawLevels() {
      dataCube.del('LevelTools')

      this.levels.forEach(level => {
        this.drawLevel(level)
      })
    },

    /**
     * Draw one level
     */
    drawLevel({ id, start_date, end_date, min_price, max_price, direction = 1 }) {
      const level = {
        type: 'LevelTool',
        name: 'LevelTools',
        data: [],
        settings: {
          id,
          p1: [start_date, min_price],
          p2: [end_date, max_price],
          direction,
          $selected: false,
          $state: 'finished',
          $uuid: uid(),
        },
      }

      dataCube.add('onchart', level)
    },

    /**
     * Create new level
     */
    async createNewLevel({ startDate, endDate, minPrice, maxPrice }) {
      /** Create new level in DB to get id */
      const id = await api.levels.add({
        minPrice: 0,
        maxPrice: 0,
        startDate: 0,
        endDate: 0,
        direction: 1,
      })

      /** Now we can draw level with this id */
      this.drawLevel({
        id,
        start_date: startDate,
        end_date: endDate,
        min_price: maxPrice,
        max_price: minPrice,
      })

      this.$patch(state => {
        state.levels.push({
          id,
          direction: 1,
        })
      })
    },

    /**
     * Set selected level id
     */
    setSelectedId(id) {
      this.$patch({
        selectedId: id,
      })
    },

    /**
     * Set direction
     */
    async setDirection(id, direction) {
      await api.levels.update({
        rectId: id,
        ...this.selectedLevel,
        direction,
      })

      this.selectedLevel.direction = direction

      dataCube
        .get('LevelTools')
        .find(l => l.settings.id === id)
        .settings.direction = direction
    },

    async remove(id) {
      await api.levels.remove({
        rectId: id,
      })

      this.setSelectedId(null)

      this.$patch({
        levels: this.levels.filter(l => l.id !== id),
      })

      this.drawLevels()
    },

  },
})

const levelsStore = useLevelsStore()