import { defineStore } from 'pinia'
import api from '../api'

export const useTickersStore = defineStore('tickers', {
  state: () => ({
    whiteList: [],
    brokers: [],
  }),

  actions: {
    async fetch() {
      if (!this.brokers.length) {
        const tickers = await api.getTickers()
        const brokers = await api.getBrokers()

        this.$patch({
          whiteList: tickers,
          brokers,
        })
      }
    },

    getBroker(brokerData) {
      if (brokerData) {
        return this.brokers.find(b => {
          console.log('------> brokerId', b.brokerId, brokerData.brokerId)
          console.log('------> ticker', b.ticker, brokerData.ticker)

          return b.brokerId == brokerData.brokerId && b.ticker === brokerData.ticker
        })
      }
    },

  },
})
