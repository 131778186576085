import { defineStore } from 'pinia'
import { calcProfitAndLoss } from '@aitt/shared/math'
import api from '../api'
import { useChartStore } from '../store/chart'
import { dataCube } from '../store/chartDataCube'
import { socket } from '../api/socket'

const chartStore = useChartStore()

export const POSITION_STATE = {
  New: 'new',
  Stopped: 'stopped',
  Safed: 'safed',
  SafedAndStopped: 'safed_and_stopped',
  SafedAndTaken: 'safed_and_taken',
  Canceled: 'canceled',
}

// eslint-disable-next-line no-unused-vars
const mockedPositions = [
  {
    id: 1,
    patternId: 1,

    /* new | stopped | safed | safed_and_stopped | safed_and_taken | canceled  */
    state: 'safed_and_taken',
    ticker: 'BTCUSDT',

    open_price: 19200,
    open_date: 1666125105028,

    safe_price: 19500,
    safe_date: 1666125616457,

    take_price: 19800,
    take_date: 1666126121038,

    stop_price: 19000,
    stop_date: 0,

    cancel_price: 0,
    cancel_date: 0,

    quantity: 0.0025,
    safe_quantity: 0,
  },
]

export const usePositionsStore = defineStore('positions', {
  state: () => ({
    positions: [],
  }),

  getters: {
    getPositions: state => {
      const positions = state.positions.map(position => {
        let takePrice = position.take_price
        let safePrice = position.safe_price

        if (position.state === POSITION_STATE.New) {
          takePrice = chartStore.realtimeCandle.close
          safePrice = chartStore.realtimeCandle.close
        }

        if (position.state === POSITION_STATE.Safed) {
          takePrice = chartStore.realtimeCandle.close
        }

        const res = calcProfitAndLoss({
          quantity: position.quantity,
          price: position.open_price,
          stopPrice: position.stop_price,
          safePrice: safePrice,
          takePrice: takePrice,
        })

        let profit = '—'

        if ([POSITION_STATE.New, POSITION_STATE.Safed, POSITION_STATE.SafedAndTaken].includes(position.state)) {
          profit = res.profit
        }

        if (position.state === POSITION_STATE.Stopped) {
          profit = -res.loss
        }

        if (position.state === POSITION_STATE.SafedAndStopped) {
          profit = res.safe
        }

        return {
          ...position,
          profit,
        }
      })

      return positions.sort((a, b) => b.open_date - a.open_date)
    },
  },

  actions: {
    /**
     * Load positions
     */
    async fetch() {
      const positions = await api.getPositions()

      this.$patch({
        positions: positions,
      })

      this.drawPositions(positions)
    },

    /**
     * Draw all positions from the state
     */
    drawPositions() {
      dataCube.del('Positions')

      this.positions.forEach(position => {
        this.drawPosition(position)
      })
    },

    /**
     * Draw existing position
     * @param {*} position from database
     */
    drawPosition({
      state,
      open_price,
      open_date = 0,

      safe_price,
      safe_date = 0,

      take_price,
      take_date = 0,

      stop_price,
      stop_date,

      cancel_price = 0,
      cancel_date = 0,
    }) {
      const currentDate = chartStore.realtimeCandle.date
      let finalDate = Math.max(take_date, stop_date, cancel_date)

      if (!finalDate) {
        finalDate = currentDate
      }

      if (!safe_date) {
        safe_date = finalDate
      }

      if (!take_date) {
        take_date = finalDate
      }

      if (!stop_date) {
        stop_date = finalDate
      }

      const openLine = {
        type: 'line',
        points: [
          [open_date, open_price],
          [open_date + 60000, open_price],
        ],
        color: 'rgba(255, 255, 255, 1)',
        width: 5,
        dashed: null,
      }

      const takeLine = {
        type: 'line',
        points: [
          [open_date, take_price],
          [take_date, take_price],
        ],
        color: 'rgba(10, 132, 255, 1)',
        width: 2,
        dashed: null,
      }

      const stopLine = {
        type: 'line',
        points: [
          [open_date, stop_price],
          [stop_date, stop_price],
        ],
        color: 'rgba(255, 0, 0, 0.8)',
        width: 2,
        dashed: null,
      }

      const safeLine = {
        type: 'line',
        points: [
          [open_date, safe_price],
          [safe_date, safe_price],
        ],
        color: 'rgba(255, 255, 0, 0.8)',
        width: 2,
        dashed: null,
      }

      /**
       * Drawing price direction line
       */
      const priceLine = {
        type: 'line',
        points: [],
        color: 'rgba(255, 255, 255, 0.8)',
        width: 1,
        dashed: [5, 5],
      }

      if (state === POSITION_STATE.New) {
        priceLine.points = [
          [open_date, open_price],
        ]

        stopLine.color = 'rgba(255, 0, 0, 0.2)'
        takeLine.color = 'rgba(10, 132, 255, 0.2)'
        safeLine.color = 'rgba(255, 255, 0, 0.2)'
      }

      if (state === POSITION_STATE.Safed) {
        priceLine.points = [
          [open_date, open_price],
          [safe_date, safe_price],
        ]

        stopLine.color = 'rgba(255, 0, 0, 0.2)'
        takeLine.color = 'rgba(10, 132, 255, 0.2)'
      }

      if (state === POSITION_STATE.SafedAndStopped) {
        priceLine.points = [
          [open_date, open_price],
          [safe_date, safe_price],
          [stop_date, stop_price],
        ]

        takeLine.color = 'rgba(10, 132, 255, 0.2)'
      }

      if (state === POSITION_STATE.SafedAndTaken) {
        priceLine.points = [
          [open_date, open_price],
          [safe_date, safe_price],
          [take_date, take_price],
        ]

        stopLine.color = 'rgba(255, 0, 0, 0.2)'
      }

      if (state === POSITION_STATE.Stopped) {
        priceLine.points = [
          [open_date, open_price],
          [stop_date, stop_price],
        ]

        safeLine.color = 'rgba(255, 255, 0, 0.2)'
        takeLine.color = 'rgba(10, 132, 255, 0.2)'
      }

      if ([POSITION_STATE.Safed, POSITION_STATE.New].includes(state)) {
        priceLine.points.push([
          chartStore.realtimeCandle.date,
          chartStore.realtimeCandle.close,
        ])
      }

      if (state === POSITION_STATE.Canceled) {
        priceLine.points = [
          [open_date, open_price],
          [cancel_date, cancel_price],
        ]

        stopLine.color = 'rgba(255, 0, 0, 0.2)'
        takeLine.color = 'rgba(10, 132, 255, 0.2)'
        safeLine.color = 'rgba(255, 255, 0, 0.2)'
      }

      const lines = [
        priceLine,
        openLine,
        takeLine,
        stopLine,
        safeLine,
      ]

      dataCube.add('onchart', {
        type: 'LinesOverlay',
        name: 'Positions',
        data: [],
        settings: {
          lines,
          'z-index': 3,
        },
      })
    },

    /**
     * Toggle listening for positions update
     * @param {boolean} state
     */
    async listenPositionsUpdate(state) {
      if (state === true) {
        socket.on('positions-update', this.onPositionsUpdate)
      } else {
        socket.off('positions-update', this.onPositionsUpdate)
      }
    },

    /**
     * Positions update socket event handler.
     * Fetch positions.
     */
    onPositionsUpdate() {
      this.fetch()
    },

  },
})

const store = usePositionsStore()

chartStore.$subscribe((mutation, state) => {
  if (mutation.payload?.realtimeCandle) {
    store.drawPositions()
  }
})