<template>
  <div class="ui-segmented-picker">
    <router-link
      v-for="(item, i) in items"
      :key="i"
      :to="item.to"
      class="ui-segmented-picker__button"
    >
      {{ item.caption }}
    </router-link>
  </div>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/require-prop-types
    value: {
      type: Number,
      default: 0,
    },

    items: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      uid: 'input-' + (Math.random() + 1).toString(36).substring(7),
    }
  },

  computed: {
    propModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    changeHandler(event) {
      let value = event.target.value

      if (value === '' && this.type === 'number') {
        value = undefined
      }

      this.$emit('input', value)
    },
  },
}
</script>

<style lang="stylus">

.ui-segmented-picker
  --height 32px
  --button-height 28px
  --button-line-height 16px
  --button-line-top 6px

  display flex
  background-color var(--c-fills-tertiary)
  border-radius 8px
  align-items center
  height var(--height)
  padding 0 2px

  justify-content center

  &__button
    position relative
    border-radius 7px
    flex 1
    height var(--button-height)
    line-height var(--button-height)
    text-align center
    color var(--c-labels-primary)
    font-size var(--footnote-13)
    font-weight 500
    cursor pointer
    text-decoration none

    @media $desktop
      &:hover
        color var(--c-labels-secondary)

    @media $mobile
      &:hover
        color inherit

    &:before
      position absolute
      content ''
      width 1px
      height var(--button-line-height)
      background-color var(--c-separators-non-opaque)
      left 0
      top var(--button-line-top)

    &--active,
    &.router-link-active
      background-color var(--c-backgrounds-primary)
      box-shadow var(--c-shadow-segment)

      &:before
          display none

      & + .ui-segmented-picker__button
        &:before
          display none

    &:first-child
      &:before
        display none

    &:hover
      color var(--c-labels-primary)

  &[large]
    --height 40px
    --button-height 36px
    --button-line-height 28px
    --button-line-top 6px

html.dark
  .ui-segmented-picker__button--active,
  .ui-segmented-picker__button.router-link-active
    background-color var(--c-backgrounds-primary-transparent)
</style>
