import { defineStore } from 'pinia'
import * as time from '@aitt/shared/time'
import api from '../api'
import { socket } from '../api/socket'
import { dataCube } from './chartDataCube'
import { DAY, MINUTE } from '@aitt/shared/time.js'

export const useChartStore = defineStore('chart', {
  state: () => ({
    brokerId: 4,
    ticker: 'BTCUSDT',
    timeframe: 1,
    startDate: 0,
    endDate: 0,

    realtimeCandle: {
      close: 0,
      date: Date.now(),
      high: 0,
      low: 0,
      open: 0,
    },

    figures: [
      {
        type: 'ru21',
        options: {
          patternMinCandle: 15,
          patternMaxCandle: 90,
          maxAsymmetryY: 0.35,
        },
        brokerData: {},
      },

      // {
      //   type: 'diapason',
      //   options: {},
      //   brokerData: {},
      // },
    ],

    newPosition: {
      shown: false,
      startDate: 0,
      startPrice: 0,
      take: 0,
      stop: 0,
      safe: 0,
      direction: 1,
    },

    chartRange: [0, 0],

    activePatternId: 0,
    activePattern: null,
  }),

  getters: {

    brokerData: state => {
      return {
        brokerId: state.brokerId,
        ticker: state.ticker,
        timeframe: state.timeframe,
        startDate: state.startDate,
        endDate: state.endDate,
      }
    },

    getNewPosition: state => {
      return state.newPosition
    },

    getCandlesBounds: state => {
      const candles = dataCube.get('chart.data')[0]

      let minPrice = 0
      let maxPrice = 1

      if (candles) {
        minPrice = Math.min(...candles.map(c => c[3]))
        maxPrice = Math.max(...candles.map(c => c[2]))
      }

      return {
        minPrice,
        maxPrice,
      }
    },
  },

  actions: {
    async setBrokerData(brokerData) {
      this.$patch({
        ...brokerData,
      })
    },

    async setActivePatternId(patternId) {
      this.$patch(state => {
        state.activePatternId = patternId
      })
    },

    async setActivePattern(pattern) {
      this.$patch(state => {
        state.activePattern = pattern
      })
    },

    async setNewPosition(newPosition) {
      this.$patch(state => {
        state.newPosition = newPosition
      })
    },

    async calcInitialPositionValues() {
      this.$patch(state => {
        let safe = 0
        let take = 0
        let stop = 0

        if (state.activePattern) {
          safe = state.activePattern.rightShoulderBegin.price + ((Math.abs(state.activePattern.headBegin.price - state.activePattern.headEnd.price)) * state.newPosition.direction)
          take = safe + (150 * state.newPosition.direction)
          stop = state.activePattern.headBegin.price - (50 * state.newPosition.direction)
        } else {
          const sp = state.newPosition.startPrice
          const pc = 0.001

          safe = sp + (sp * pc * state.newPosition.direction)
          take = safe + (safe * pc * state.newPosition.direction)
          stop = sp - (sp * pc * state.newPosition.direction)
        }

        state.newPosition = {
          ...state.newPosition,
          safe,
          take,
          stop,
        }
      })
    },

    async loadCandles({ startDate, endDate, limit = 200, overlap = 0 } = {}) {
      const overlapMs = overlap * this.timeframe * time.MINUTE

      if (endDate) {
        endDate += overlapMs
      }

      if (startDate) {
        startDate -= overlapMs
      }

      const params = {
        ticker: this.ticker,
        brokerId: this.brokerId,
        timeframe: this.timeframe,
        startDate,
        endDate,
      }

      if (!endDate || !startDate) {
        params.limit = limit
      }

      const candles = await api.getCandles(params)

      return candles
    },

    async loadPatterns({ startDate, endDate, overlap = 0 } = {}) {
      const overlapMs = overlap * this.timeframe * time.MINUTE

      if (endDate) {
        endDate += overlapMs
      }

      if (startDate) {
        startDate -= overlapMs
      }

      const brokerData = {
        ticker: this.ticker,
        brokerId: this.brokerId,
        timeframe: this.timeframe,
        startDate,
        endDate,
      }

      const figuresConfig = JSON.parse(JSON.stringify(this.figures))

      figuresConfig.forEach(f => {
        f.brokerData = brokerData
      })

      const patterns = await api.getPatterns(figuresConfig)

      return patterns
    },

    async loadPatternPrices(patternId) {
      const pattern = await api.getPatternPrices(patternId)

      if (pattern.direction === undefined) {
        pattern.direction = -1
      }

      return pattern
    },

    async listenRealTimeCandle(state) {
      if (state === true) {
        socket.on('candle', this.onRealtimeCandle)
      } else {
        socket.off('candle', this.onRealtimeCandle)
      }
    },

    onRealtimeCandle(candle) {
      if (this.brokerData.ticker === candle.symbol) {
        this.$patch({
          realtimeCandle: {
            date: candle.startTime,
            close: parseFloat(candle.close),
            open: parseFloat(candle.open),
            low: parseFloat(candle.low),
            high: parseFloat(candle.high),
          },
        })
      }
    },

    setChartRange(range) {
      this.$patch(state => {
        state.chartRange = range
      })
    },

    /**
     * Draw day delimiters
     */
    drawDayDelimiters() {
      const lines = []
      const days = this.getDayDelimiters()

      if (this.timeframe < 1440) {
        days.forEach(day => {
          lines.push({
            type: 'line',
            points: [ [day, 0], [day, 9999999] ],
            color: 'rgba(128, 128, 128, 0.25)',
            width: 1,
            dashed: [5, 5],
          })
        })

        dataCube.del('DayDelimiters')
        dataCube.add('onchart', {
          type: 'LinesOverlay',
          name: 'DayDelimiters',
          data: [],
          settings: {
            lines,
            'z-index': 1,
          },
        })
      } else {
        dataCube.del('DayDelimiters')
      }
    },

    /**
     * Returns array of days timestamps
     */
    getDayDelimiters() {
      const days = []
      let startDate = this.startDate
      const endDate = this.endDate
      const timeZoneOffset = -new Date().getTimezoneOffset() * MINUTE

      startDate = new Date(startDate)
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
      startDate = startDate.getTime() + timeZoneOffset

      days.push(startDate)

      while (startDate < endDate) {
        startDate += DAY

        days.push(startDate)
      }

      return days
    },

  },
})