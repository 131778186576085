<template>
  <Sheety
    ref="bottomSheet"
    :options="sheetOptions"
    @state="stateChangedHandler"
  >
    <div class="sheety-content">
      <div class="sheety-content__tab">
        <slot />
      </div>

      <div class="sheety-content__tabs">
        <ui-segmented-picker
          v-model="tabIndex"
          large
          :items="tabItems"
        />
      </div>
    </div>
  </Sheety>
</template>

<script>
import Sheety from '../components/Sheety'
import UiSegmentedPicker from '../ui/UiSegmentedPicker'

export default {
  components: {
    Sheety,
    UiSegmentedPicker,
  },

  data() {
    return {
      tabIndex: 0,

      sheetOptions: {

      },

      tabItems: [
        // {
        //   caption: 'Profile',
        //   to: { name: 'Terminal — Profile' },
        // },
        // {
        //   caption: 'Browse',
        //   to: { name: 'Terminal — Browse' },
        // },
        {
          caption: 'Tools',
          to: { name: 'Terminal — Tools' },
        },
        {
          caption: 'Positions',
          to: { name: 'Terminal — Positions' },
        },
        // {
        //   caption: 'Trade',
        //   to: { name: 'Terminal — Trade' },
        // },
        {
          caption: 'Sell',
          to: { name: 'Terminal — Sell' },
        },
        {
          caption: 'Buy',
          to: { name: 'Terminal — Buy' },
        },

      ],
    }
  },

  watch: {
    tabIndex() {

    },
  },

  methods: {
    stateChangedHandler(state) {
      this.state = state
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.sheety-content
  display flex
  position relative
  flex-direction column
  height 100%

  &__tabs
    position sticky
    bottom 0px
    z-index 10
    padding 8px 16px 12px 16px
    background-blend-mode multiply
    backdrop-filter blur(15px)
    // background rgba(0,0,0,0.5)

    @media $mobile
      padding 8px 16px 20px 16px
      >>>.ui-segmented-picker
        --radius 20px
        border-bottom-right-radius var(--radius)
        border-bottom-left-radius var(--radius)

        &__button
          &:first-child
            border-bottom-left-radius var(--radius)
            padding-left 4px

          &:last-child
            border-bottom-right-radius var(--radius)
            padding-right 4px

  &__tab
    flex 1
    padding 32px 16px 12px 16px

</style>
