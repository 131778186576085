<template>
  <div
    v-if="brokersLoaded"
    class="terminal-layout"
  >
    <terminal-chart>
      <router-view />
    </terminal-chart>
  </div>

  <div
    v-else
    class="skeleton"
  >
    <b-icon
      icon="gem"
      animation="fade"
      font-scale="5"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { disablePageScroll } from 'scroll-lock'
import './styles/global.styl'

import TabBar from './components/TabBar'
import UiInput from './ui/UiInput'
import UiButton from './ui/UiButton'

import { useUserStore } from '@/terminal/store/user'
import { useTickersStore } from '@/terminal/store/tickers'
import TerminalChart from './views/TerminalChart.vue'

/**
 * Register global UI components
 */
Vue.component('ui-button', UiButton)
Vue.component('ui-input', UiInput)

/**
 * Use stores
 */
const userStore = useUserStore()
const tickersStore = useTickersStore()

/**
 * Check for user auth
 */
userStore.loginCheck()

export default {
  components: {
    TabBar,
    TerminalChart,
  },

  data() {
    return {
      brokersLoaded: false,
    }
  },

  async beforeMount() {
    /**
     * Force dark theme
     */
    document.documentElement.classList.add('dark')

    window.addEventListener('resize', this.setAppHeight)
    window.addEventListener('orientationchange', this.setAppHeight)
    this.setAppHeight()

    this.brokersLoaded = false
    await tickersStore.fetch()
    this.brokersLoaded = true
  },

  destroyed() {
    window.removeEventListener('resize', this.setAppHeight)
    window.addEveremoveEventListenerntListener('orientationchange', this.setAppHeight)
  },

  mounted() {
    disablePageScroll(document.body)
  },

  methods: {
    setAppHeight() {
      const doc = document.documentElement

      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    },
  },

}
</script>

<style lang="stylus" scoped>
  @import './styles/global.styl'
  @import './styles/colors.css'
  @import './styles/texts.css'
  @import './styles/fragments.css'

  .terminal-layout
    width 100%
    height var(--app-height)
    background var(--c-backgrounds-app)
    color var(--c-labels-primary)
    overflow hidden
    font-size var(--body-17)

    -webkit-user-select none
    -webkit-tap-highlight-color transparent
    -webkit-touch-callout none

  .skeleton
    display flex
    justify-content center
    align-items center
    width 100%
    height var(--app-height)
    background var(--c-backgrounds-app)
    color var(--c-accent)

    // @media $mobile
    //   padding-bottom var(--tab-bar-height)

</style>
