<template>
  <div
    ref="sheety"
    class="sheety"
    :class="{animate: !isMove}"
    :style="styles"
  >
    <div class="bar" />
    <div
      ref="pan"
      class="pan-area"
    />

    <div
      class="contents"
      data-scroll-lock-scrollable
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import breakpoints from '@aitt/frontend/src/classes/breakpoints'

// const PAN_SENSITIVITY = 15

function findClosest(needle, numbers) {
  return numbers.sort((a, b) => {
    return Math.abs(needle - a) - Math.abs(needle - b)
  })[0]
}

export default {
  props: {

  },

  data() {
    return {
      mc: null,
      isMove: false,

      heights: [68, 218, 336, 600],
      height: 336,
      left: 100,
      stableHeight: null,
      stableLeft: null,
    }
  },

  computed: {
    screenHeight() {
      return window.innerHeight
    },

    styles() {
      return {
        height: this.height + 'px',
        left: this.left + 'px',
      }
    },
  },

  mounted() {
    this.mc = new Hammer(this.$refs.pan)

    this.mc
      .get('pan')
      .set({ direction: Hammer.DIRECTION_ALL })

    this.mc.on('pan', (evt) => {
      this.height = this.stableHeight - evt.deltaY

      if (breakpoints.isTablet()) {
        this.left = this.stableLeft + evt.deltaX
      }
    })

    this.mc.on('panstart', (evt) => {
      this.isMove = true
      this.stableHeight = this.height
      this.stableLeft = this.$refs.sheety.offsetLeft
    })

    this.mc.on('panend', (evt) => {
      this.isMove = false
      this.height = this.stableHeight = findClosest(this.height, this.heights)
    })

    breakpoints.on('breakpointChange', this.breakpointChangeHandler)
  },

  beforeDestroy() {
    this.mc.destroy()
  },

  methods: {
    breakpointChangeHandler() {

    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.sheety
  --sheety-bar-top 5px
  --sheety-bar-width 45px
  --sheety-bar-height 5px
  --sheety-bar-color var(--c-grays-system-gray-3)

  width 100%
  height 250px
  z-index 100
  color var(--c-labels-primary)
  // background red
  background var(--c-backgrounds-app-alpha)

  @media $mobile
    left 0 !important

  @media $desktop
    box-shadow var(--c-shadow-sheety)
    border-radius 24px 24px 0 0

  &.animate
    transition height  .3s ease-out

  .bar
    position absolute
    width var(--sheety-bar-width)
    height var(--sheety-bar-height)
    left 50%
    top var(--sheety-bar-top)
    margin-left calc(var(--sheety-bar-width) / 2 * -1)
    border-radius 100px
    cursor pointer
    background var(--sheety-bar-color)
    pointer-events none
    z-index 1

  .pan-area
    top calc(var(--chart-timeline-height) * -1)
    right 0
    left 0
    position absolute
    height 60px
    z-index 10
    // background rgba(255,0,0,0.1)

    .bar
      & hover
        cursor: grab

        &:active
          cursor grabbing

  .contents
    position relative
    border-radius 24px 24px 0 0
    height 100%
    box-sizing border-box
    overflow-y auto
    //overscroll-behavior contain

    // @media $desktop
    //   background-blend-mode multiply
    //   backdrop-filter blur(15px)

</style>
