/**
 * Lineary projects x from [a, b] to [A, B]
 * @param {Number} x
 * @param {Number} a
 * @param {Number} b
 * @param {Number} A
 * @param {Number} B
 * @returns {Number}
 */
export function linear(x, a, b, A, B) {
  return x * (B - A) / (b - a) + (A * b - B * a) / (b - a)
}

/**
 * Returns array of unique values
 * @param {Array} values
 * @returns {Array}
 */
export function unique(values) {
  return Array.from(new Set(values))
}

/**
 * Returns round number with specified float
 * @see: https://stackoverflow.com/a/49729715/2933468
 * @param {Number} n
 * @param {Number} fixed
 */
export function float(n, fixed) {
  const d = 10 ** fixed

  return Math.round(n * d) / d
}

/**
 * Returns true if vlue in given range
 * @param {Number} value
 * @param {Number} mid
 * @param {Number} delta
 * @returns {Boolean}
 */
export function valueInRange(value, mid, delta) {
  if (value < (mid - delta)) {
    return false
  }

  if (value > (mid + delta)) {
    return false
  }

  return true
}

export const RAD_TO_DEG = 180 / Math.PI
export const DEG_TO_RAD = 1 / RAD_TO_DEG

export function angle(a, b, dateRange, priceRange) {
  const y = (b.price - a.price) / priceRange
  const x = (b.date - a.date) / dateRange

  return Math.atan2(y, x) * RAD_TO_DEG
}

export function angleFromThreePoints(a, b, c) {
  const dateRange = c.date - a.date
  const priceRange = Math.max(a.price, b.price, c.price) - Math.min(a.price, b.price, c.price)

  return angle(a, c, dateRange, priceRange)
}

export function calcSafePart({ price, safePrice, stopPrice }) {
  const stopDelta = Math.abs(stopPrice - price)
  const safeDelta = Math.abs(safePrice - price)

  const part = stopDelta / (stopDelta + safeDelta)

  if (part >= 0 && part <= 1) {
    return part
  }

  throw new Error(`Invalid safe part value=${part} for price=${price}, safePrice=${safePrice}, stopPrice=${stopPrice}`)
}

export function calcProfitAndLoss({ quantity, price, stopPrice, safePrice, takePrice }) {
  const safePart = calcSafePart({
    price: price,
    safePrice,
    stopPrice,
  })

  const dir = Math.sign(takePrice - stopPrice)

  const safeQuantity = quantity * safePart
  const takeQuantity = quantity - safeQuantity

  const loss = (price - stopPrice) * dir * quantity
  const safe = (safePrice - price) * dir * safeQuantity + (stopPrice - price) * dir * takeQuantity
  const profit = (safePrice - price) * dir * safeQuantity + (takePrice - price) * dir * takeQuantity

  return {
    loss,
    safe,
    profit,
  }
}